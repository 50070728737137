import BankingDetails from '../media/BankingDetails.pdf';

export default function Details() {
	return (
		<>
			<section className="details-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Wedding</span>
						<br /> Details
					</h1>
					<div className="line"></div>
				</div>
				<h3>
					1.30 PM <span className="smallCaps">for</span> 2 PM
				</h3>
				<p className="event-item">Guest Arrival</p>
				<p className="event-place">Au d'Hex, Wellington</p>
				<hr />
				<h3>
					2 PM <span className="smallCaps">to</span> 2.30 PM
				</h3>
				<p className="event-item">Ceremony</p>
				<p className="event-place">The Deck, Au d'Hex</p>
				<hr />
				<h3>2.45 PM</h3>
				<p className="event-item">Pre-drinks</p>
				<p className="event-place">Rose Garden</p>
				<hr />
				<h3>5.15 PM</h3>
				<p className="event-item">Reception</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>5.30 PM</h3>
				<p className="event-item">Starters</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>6 PM</h3>
				<p className="event-item">Speeches</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>7.15 PM</h3>
				<p className="event-item">Grooms Speech + Cake-Cutting</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>7.30 PM</h3>
				<p className="event-item">First Dance + Party Begins</p>
				<p className="event-place">Dance Floor</p>
				<hr />
				<h3>
					<span className="cursive">Party ends at</span> Midnight
				</h3>
				<div className="heading">
					<div className="line"></div>
					<h5>Dress&nbsp;Code</h5>
					<div className="line"></div>
				</div>
				<p className="no-spacing">
					Our wedding theme is <em>secret garden</em>
				</p>
				<br />
				<p className="no-spacing">
					We'd love for our guests to join in the magic by dressing in vibrant
					colors such as these:
				</p>
				<div className="color-dots">
					<div className="color-dot one"></div>
					<div className="color-dot two"></div>
					<div className="color-dot three"></div>
					<div className="color-dot four"></div>
				</div>
				<p className="no-spacing">
					We encourage our guests to have fun with our theme and wear playful
					patterns!
				</p>
				<br />
				<br />
				<a
					href="https://za.pinterest.com/simonesolomon93/s-p-wedding-dress-code/?invite_code=0714783ede9049d0b725d8387d40f200&sender=353673514395405451"
					target="_blank"
					rel="noreferrer"
					className="button-link"
				>
					View Pinterest Board
				</a>
			</section>
			<section className="gifts-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Wedding</span>
						<br /> Gifts
					</h1>
					<div className="line"></div>
				</div>
				<p>
					Your presence at our wedding is the greatest gift we could ever ask
					for, and we truly mean it!
				</p>
				<p>
					However, if you would like to contribute something extra special,
					we've set up a honeymoon fund to help us create unforgettable memories
					as we embark on this exciting new chapter together.
				</p>
				<p>
					Your generosity will go towards making our dream trip a reality, and
					we'll think of you with gratitude every step of the way.
				</p>
				<p>
					Thank you for your love, support, and for being part of our journey!
				</p>
				<br />
				<br />
				<a
					href={BankingDetails}
					target="_blank"
					rel="noreferrer"
					className="button-link"
				>
					Donate
				</a>
			</section>
		</>
	);
}
