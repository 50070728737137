import { useLocation } from 'react-router-dom';
import accommodation from '../media/accommodation.png';
import AccommodationOptions from '../media/AccommodationOptions.pdf';

export default function Accommodation() {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const info = params.get('info');
	return (
		<section className="accommodation-section">
			<img src={accommodation} alt="" className="accommodation-image" />
			{/* Conditional Info for Bridal Party */}
			{info === 'bridalparty' && (
				<div>
					<p>
						For our bridal party, we've reserved a special stay at the beautiful
						Au d'hex Guesthouse. Located right on the estate, this charming
						guesthouse blends comfort and luxury, offering an ideal retreat for
						our closest family and friends.
					</p>
					<p>
						The guesthouse can accommodate up to 20 guests, ensuring a cozy and
						intimate atmosphere for those dearest to us. Each room is elegantly
						furnished, with stunning views of the vineyards and surrounding
						mountains, creating a relaxing and picturesque experience.
					</p>
					<p>Please also join us on for dinner on Friday at</p>
					<p>
						<strong>5PM for 5:30PM</strong>
					</p>
					<p>
						We're thrilled to share this wonderful space with you, and we hope
						it provides the perfect beginning to our celebrations together!
					</p>
					<hr />
				</div>
			)}
			{/* Conditional Info for non-bridal party */}
			{info == null && (
				<div>
					<p>
						We have curated a list of accommodation options to make your stay as
						comfortable and convenient as possible while celebrating with us.
						Each option is within easy reach of our wedding venue and we will
						also have a shuttle service available for transport between the
						venue.
					</p>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.0552167358264!2d19.011680575541693!3d-33.62981027331889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcd0f4cae555bb9%3A0x7ae49ea24e39b3d3!2sAu%20d&#39;%20Hex%20Estate!5e0!3m2!1sen!2sza!4v1734079351073!5m2!1sen!2sza"
						width="90%"
						height="300"
						title="wedding-map"
					></iframe>
					<br />
					<br />
					<br />
					<a
						href={AccommodationOptions}
						rel="noreferrer"
						target="_blank"
						className="button-link"
					>
						See Options
					</a>
				</div>
			)}
		</section>
	);
}
