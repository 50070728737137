export default function Gallery() {
	const images = [
		require('../media/gallery/Gallery (1).png'),
		require('../media/gallery/Gallery (3).png'),
		require('../media/gallery/Gallery (4).png'),
		require('../media/gallery/Gallery (5).png'),
		require('../media/gallery/Gallery (8).png'),
		require('../media/gallery/Gallery (9).png'),
		require('../media/gallery/Gallery (10).png'),
		require('../media/gallery/Gallery (12).png'),
		require('../media/gallery/Gallery (13).png'),
		require('../media/gallery/Gallery (14).png'),
		require('../media/gallery/Gallery (15).png'),
		require('../media/gallery/Gallery (17).png'),
		require('../media/gallery/Gallery (18).png'),
		require('../media/gallery/Gallery (19).png'),
		require('../media/gallery/Gallery (20).png'),
		require('../media/gallery/Gallery (21).png'),
		require('../media/gallery/Gallery (22).png'),
		require('../media/gallery/Gallery (23).png'),
		require('../media/gallery/Gallery (24).png'),
		require('../media/gallery/Gallery (26).png'),
		require('../media/gallery/Gallery (27).png'),
		require('../media/gallery/Gallery (30).png'),
	];
	return (
		<section className="gallery-section">
			<div className="heading">
				<div className="line"></div>
				<h1>
					<span className="cursive">Photo</span>
					<br /> Gallery
				</h1>
				<div className="line"></div>
			</div>
			<div className="gallery">
				{images.map((src, index) => (
					<img key={index} src={src} alt={`Gallery ${index + 1}`} />
				))}
			</div>
		</section>
	);
}
