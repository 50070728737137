import { React, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import './App.css';
import { FaWhatsapp } from 'react-icons/fa';
import Monogram from './media/monogram.png';
import Welcome from './components/Welcome';
import RSVP from './components/RSVP';
import OurStory from './components/OurStory';
import AboutTheVenue from './components/AboutTheVenue';
import Accommodation from './components/Accommodation';
import Details from './components/Details';
import FAQ from './components/FAQ';
import Gallery from './components/Gallery';

function App() {
	const [menuOpen, setMenuOpen] = useState(false);
	const location = useLocation(); // Get the current route
	const [slideIn, setSlideIn] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const closeMenu = () => {
		setMenuOpen(false);
	};

	const isActive = (path) => location.pathname === path; // Check if a path matches the current route

	// Preserve query string if it exists
	const queryString = location.search; // Get the current query string (e.g., "?info=bridalparty")

	useEffect(() => {
		// Only trigger the slide-in animation if the location is the homepage
		if (location.pathname === '/') {
			// Add a delay before the slide-in effect is triggered
			const timer = setTimeout(() => {
				setSlideIn(true); // Trigger the animation after the delay
			}, 500); // Adjust the delay time (in ms) as needed

			// Cleanup timeout if the component is unmounted or location changes
			return () => clearTimeout(timer);
		}
	}, [location.pathname]); // Runs whenever the pathname changes

	return (
		<div className="App">
			<div className="screen-size-message">
				<img src={Monogram} alt="" className="monogram" />
				<h5>
					Please note this website is optimized for mobile devices. Please try
					accessing this page from another device.
				</h5>
				<h4>with love,</h4>
				<h3>Simone & Pavo</h3>
			</div>
			<div className="all-main-content">
				<header className={`header ${slideIn ? 'slide-in' : ''}`}>
					<nav>
						<h6>Simone & Pavo</h6>
						<button
							className={`menu-button ${menuOpen ? 'open' : ''}`}
							onClick={toggleMenu}
						>
							<span></span>
							<span></span>
							<span></span>
						</button>
						<ul className={menuOpen ? 'open' : ''}>
							<li className={isActive('/') ? 'active' : ''}>
								<Link to={`/${queryString}`} onClick={closeMenu}>
									Welcome
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/rsvp') ? 'active' : ''}>
								<Link to={`/rsvp${queryString}`} onClick={closeMenu}>
									RSVP
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/our-story') ? 'active' : ''}>
								<Link to={`/our-story${queryString}`} onClick={closeMenu}>
									Our Story
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/about-the-venue') ? 'active' : ''}>
								<Link to={`/about-the-venue${queryString}`} onClick={closeMenu}>
									About the Venue
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/accommodation') ? 'active' : ''}>
								<Link to={`/accommodation${queryString}`} onClick={closeMenu}>
									Accommodation
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/wedding-details') ? 'active' : ''}>
								<Link to={`/wedding-details${queryString}`} onClick={closeMenu}>
									Wedding Details
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/faqs') ? 'active' : ''}>
								<Link to={`/faqs${queryString}`} onClick={closeMenu}>
									FAQs
								</Link>
								<div className="line"></div>
							</li>
							<li className={isActive('/photo-gallery') ? 'active' : ''}>
								<Link to={`/photo-gallery${queryString}`} onClick={closeMenu}>
									Photo Gallery
								</Link>
								<div className="line"></div>
							</li>
						</ul>
					</nav>
				</header>
				<Routes>
					<Route path="/" element={<Welcome />} />
					<Route path="/rsvp" element={<RSVP />} />
					<Route path="/our-story" element={<OurStory />} />
					<Route path="/about-the-venue" element={<AboutTheVenue />} />
					<Route path="/accommodation" element={<Accommodation />} />
					<Route path="/wedding-details" element={<Details />} />
					<Route path="/faqs" element={<FAQ />} />
					<Route path="/photo-gallery" element={<Gallery />} />
				</Routes>
				<section className="contact-section">
					<div className="heading">
						<div className="line"></div>
						<h1>
							<span className="cursive">Contact</span>
							<br /> Us
						</h1>
						<div className="line"></div>
					</div>
					<p>
						If you have any questions or need assistance with RSVPs, travel
						arrangements, or anything else, please feel free to reach out to us!
					</p>
					<div className="contact-links">
						<a href="https://wa.me/971582854404" className="whatsapp-link">
							<FaWhatsapp className="icon" /> Simone
						</a>
						<a href="https://wa.me/27814138078" className="whatsapp-link">
							<FaWhatsapp className="icon" /> Pavo
						</a>
					</div>
				</section>

				<footer>
					<p>
						2024 &copy; <a href="https://wed-ify.co.za">Wedify.co.za</a>
					</p>
				</footer>
			</div>
		</div>
	);
}

export default App;
