import venue from '../media/venue.png';

export default function AboutTheVenue() {
	return (
		<section className="venue-section">
			<div className="heading">
				<div className="line"></div>
				<h1>
					<span className="cursive">About&nbsp;the</span>
					<br /> Venue
				</h1>
				<div className="line"></div>
			</div>
			<img src={venue} alt="" className="venue-image" />
			<p>
				Nestled in the scenic heart of Wellington, Au d'Hex is a venue where
				elegance meets the beauty of nature. This enchanting estate offers a
				blend of historic charm and modern luxury, making it an unforgettable
				setting for our wedding celebration.
			</p>
			<br />
			<p>
				With its lush vineyards, picturesque gardens, and majestic mountain
				views, Au d'Hex provides a serene and sophisticated atmosphere. From the
				grand architecture of its halls to the cozy outdoor spaces, every corner
				of the estate has been thoughtfully designed to create a magical
				experience.
			</p>
			<br />
			<p>
				Join us in celebrating our love surrounded by the timeless allure of
				this exquisite venue.
			</p>
			<hr />
		</section>
	);
}
