import { useState } from 'react';

export default function FAQ() {
	// Logic for FAQ section

	const [activeIndex, setActiveIndex] = useState(null);

	const toggleQuestion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const faqData = [
		{
			question: 'What should I wear?',
			answer:
				'The dress code is formal. Guests are encouraged to add a pop of color with pastels, florals, or patterns to complement our Secret Garden theme.',
		},
		{
			question: 'Can I bring a plus-one?',
			answer:
				'Please refer to your invitation for specifics. If your plus-one is included, their name will be listed on the invite.',
		},
		{
			question: 'Are Kids Allowed?',
			answer:
				'We love your little ones, but only a select few children will be joining us for the day. If your child is invited, their name will be included on the invitation.',
		},
		{
			question: 'Where will the ceremony and reception take place?',
			answer:
				'Both the ceremony and reception will be held at Au d’Hex Estate.',
		},
		{
			question: 'Is there parking available at the venue?',
			answer:
				'Yes, parking will be available onsite. Please follow the signs for guest parking upon arrival.',
		},
		{
			question: 'Will there be a cash bar?',
			answer:
				'No need to worry about cash! The bar will be fully stocked and open.',
		},
		{
			question: 'What time should I arrive?',
			answer:
				'The ceremony will begin promptly at 2.pm. Please arrive 15-20 minutes earlier to find your seat.',
		},
		{
			question: 'Will the ceremony be indoors or outdoors?',
			answer:
				'The ceremony will be held outdoors in the garden, weather permitting. Please dress accordingly.',
		},
		{
			question: 'Can I take photos during the ceremony?',
			answer:
				'Yes, you’re welcome to take photos during the ceremony! We do kindly ask that you remain seated and mindful of our photographers and videographers so they can capture the best moments.',
		},
		{
			question: 'What if I have dietary restrictions?',
			answer:
				'We’ve planned a variety of menu options to accommodate different dietary needs. If you have specific restrictions, please let us know as soon as possible.',
		},
		{
			question: 'Can I post on social media?',
			answer:
				'Yes! Feel free to share the love on social media. Use our hashtag, #foreverdefreitas, so we can see your lovely photos.',
		},
		{
			question: 'Is there a specific gift registry?',
			answer:
				'Your presence is our gift! However, if you’d like to bless us with a gift, we would appreciate a contribution towards our honeymoon fund. It would mean the world to us. Banking details can be found on our wedding website. ',
		},
		{
			question: 'Will there be transport to and from the venue?',
			answer:
				'Yes! For guests staying in nearby Airbnbs or hotels, a shuttle service will be available to and from the venue. More details will be shared closer to the wedding day.',
		},
		{
			question: 'Who should I contact for additional questions?',
			answer:
				'If you have any further questions, feel free to reach out to Simone or Pavo on WhatsApp. We’re happy to help!',
		},
	];
	return (
		<section className="faq-section">
			<div className="heading">
				<div className="line"></div>
				<h1>
					<span className="cursive">Your</span>
					<br /> FAQ<span className="lowercase">s</span>
				</h1>
				<div className="line"></div>
			</div>
			{faqData.map((item, index) => (
				<div key={index} className="faq-item">
					<div
						className="question-content"
						onClick={() => toggleQuestion(index)}
					>
						<p>{item.question}</p>
						<p>{activeIndex === index ? '-' : '+'}</p>
					</div>
					{activeIndex === index && <p className="answer">{item.answer}</p>}
				</div>
			))}
		</section>
	);
}
