export default function OurStory() {
	return (
		<section className="ourStory-section">
			<div className="heading">
				<div className="line"></div>
				<h1>
					<span className="cursive">Our</span>
					<br /> Story
				</h1>
				<div className="line"></div>
			</div>
			<p>
				At fifteen and seventeen, we first said hello, through a friend named
				Jesse, years ago. We crossed paths briefly, here and there, but destiny
				had plans to share. On Simone's eighteenth, with friends we'd meet, a
				casual smoke, a birthday treat. We swapped BBM pins, texted till dawn,
				and just like that, a bond was born. From matric ball dates to years
				that flew, twelve years later, Pavo knew: This was the love he'd always
				keep, So he proposed, and Simone took the leap. Now here we stand, ready
				to say, “Forever starts with our wedding day!”
			</p>
			<hr />
		</section>
	);
}
