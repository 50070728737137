import heroImage from '../media/mainHeroImage.png';
import Countdown from 'react-countdown';
import countdownOne from '../media/countdownOne.png';
import countdownTwo from '../media/countdownTwo.png';
import countdownThree from '../media/countdownThree.png';
import countdownFour from '../media/countdownFour.png';
import React, { useState, useEffect, useRef } from 'react';
export default function Welcome() {
	// Countdown

	const [visibleSections, setVisibleSections] = useState({}); // Track visibility of sections
	const countdownRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setVisibleSections((prev) => ({
							...prev,
							[entry.target.id]: true,
						}));
					}
				});
			},
			{ threshold: 0.1 } // Trigger when 10% of the element is in the viewport
		);

		const countdownItems =
			countdownRef.current.querySelectorAll('.countdown-item');
		countdownItems.forEach((item) => observer.observe(item));

		return () => observer.disconnect(); // Cleanup
	}, []);

	const renderer = ({ days, hours, minutes, seconds }) => {
		return (
			<section className="countdown-section" ref={countdownRef}>
				<div
					id="days-section"
					className={`countdown-item ${
						visibleSections['days-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownOne} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{days}</p>
						<p className="countdown-uom">days</p>
					</div>
				</div>
				<div
					id="hours-section"
					className={`countdown-item ${
						visibleSections['hours-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownTwo} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{hours}</p>
						<p className="countdown-uom">hours</p>
					</div>
				</div>
				<div
					id="minutes-section"
					className={`countdown-item ${
						visibleSections['minutes-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownThree} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{minutes}</p>
						<p className="countdown-uom">minutes</p>
					</div>
				</div>
				<div
					id="seconds-section"
					className={`countdown-item ${
						visibleSections['seconds-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownFour} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{seconds}</p>
						<p className="countdown-uom">seconds</p>
					</div>
				</div>
			</section>
		);
	};

	return (
		<>
			<section className="heroSection">
				<img src={heroImage} className="hero-image" alt=""></img>
				<div className="hero-section-content">
					<h3>Simone</h3>
					<div className="inline">
						<div className="line"></div>
						<h4>and</h4>
						<div className="line"></div>
					</div>
					<h3>Pavo</h3>
				</div>
			</section>
			<Countdown date={new Date('2025-04-05T14:00:00')} renderer={renderer} />
			<section className="invite-section">
				<p>Welcome to the wedding website of</p>
				<br />
				<p className="name">Simone Solomon</p>
				<p>and</p>
				<p className="name">Pavo De Freitas</p>
				<br />
				<p>
					We're thrilled to have you as we prepare to celebrate the next chapter
					of our love story. This site has all the information about our special
					day, so feel free to explore and discover all the details.
				</p>
				<p>
					Our celebration will be inspired by a <em>Secret Garden</em>, and we
					can't wait to share this unforgettable moment with you!
				</p>
				<br />
				<p>Saturday | April 05 | 2025</p>
				<p>Au d'Hex | Wellington</p>
			</section>
		</>
	);
}
