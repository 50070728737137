export default function RSVP() {
	return (
		<section className="rsvp-section">
			<div className="heading">
				<div className="line"></div>
				<h1>
					<span className="cursive">Kindly</span>
					<br /> RSVP
				</h1>
				<div className="line"></div>
			</div>
			<p>
				We can't wait to celebrate our special day with you! To help us plan the
				festivities, we kindly ask you to RSVP by the{' '}
				<strong> 19th of January</strong>.
			</p>
			<p>
				Let us know if you'll be joining us, and don't forget to include any
				dietary requirements or special requests - we want everyone to have an
				amazing time!
			</p>
			<br />
			<br />
			<a
				href="https://docs.google.com/forms/d/e/1FAIpQLSfbl9ce5OY2k1ZkWmDI5DPkthfrRnyFBdDzV6jixnGqyMkN_A/viewform?usp=dialog"
				className="button-link"
			>
				RSVP Here
			</a>
		</section>
	);
}
